/* Module - Visual */
$component: 'dvg-visual';

.#{$component} {
    position: relative;
    padding-top: 80px;
    overflow: hidden;
    margin: 0 auto;

    &__wrap {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        background-color: #f4f4f4;
        height: calc(100vh - 80px);
    }

    &__swiper {
        height: 100%;

        figure {
            position: relative;
            left: 0;
            margin: 0;
            width: 100%;
            height: 100%;
        }
    }
}