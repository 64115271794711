/* Module - Cards */
$component: 'dvg-cards';

.#{$component} {
    position: relative;
    overflow: hidden;
    margin: 0 auto;

    &__wrap {
        position: relative;
        max-width: 1920px;
        margin: 0 auto;
        background-color: #f0f0f0;
        height: calc(100vh - 80px);
    }
}